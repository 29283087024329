<template>
  <div class="row">
    <div class="col-lg-12">
      <button class="btn btn-primary mr-0 mr-md-2 col-12 col-lg-2 mt-2 float-right mb-0" @click="$router.push('/freedays/list')">Összes státusz</button>
      <button class="btn btn-primary mr-0 mr-md-2 col-12 col-lg-2 mt-2 float-right mb-0" @click="$router.push('/freedays/global/list')">Szabadság státuszok</button>
    </div>
    <div class="col-lg-12 mb-2">
      <p>Válaszd ki az alkalmazottat</p>
      <b-form-select
        v-model="selected_employee"
        :options="employees"
        text-field="name"
        value-field="id"
        @change="getFreeDays"
      />
    </div>
    <Calendar class="col-lg-12 calendar-settings"  :key="calendarKey" week-start="1" :enable-range-selection="true" @year-changed="handleYearChange" :year="currentYear" :data-source="calendar_data" language="hu"></Calendar>
    <hr>
    <div class="col-lg-12 row mt-3">
      <div class="col-lg-2"><span class="badge" style="background: green;display: inline-block;width: 20px"></span> Szabadság ({{ freeday }} darab)</div>
      <div class="col-lg-2"><span class="badge" style="background: purple;display: inline-block;width: 20px"></span> Táppénz ({{ sick_pay }} darab)</div>
      <div class="col-lg-2"><span class="badge" style="background: blue;display: inline-block;width: 20px"></span> Szabadnap ({{ holiday }} darab)</div>
      <div class="col-lg-2"><span class="badge" style="background: red;display: inline-block;width: 20px"></span> Igazolatlan ({{ unverified }} darab)</div>
      <div class="col-lg-2"><span class="badge" style="background: yellow;display: inline-block;width: 20px"></span> Később érkezett ({{ late }} darab)</div>
      <div class="col-lg-2"><span class="badge" style="background: #F05501;display: inline-block;width: 20px"></span> Hamarabb elment ({{ gone_sooner }} darab)</div>
      <div class="col-lg-2 mt-1"><span class="badge" style="background: #913831;display: inline-block;width: 20px"></span> Fizetetlen ({{ not_paid }} darab)</div>
    </div>
  </div>
</template>

<script>

import Calendar from 'v-year-calendar'
import axios from 'axios'
import {
  BFormSelect,
} from 'bootstrap-vue'

export default {
  components: {
    Calendar,
    BFormSelect,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      calendarKey: 0,
      calendar_data: [],
      selected_employee: null,
      employees: [],
      freeday: 0,
      holiday: 0,
      sick_pay: 0,
      unverified: 0,
      late: 0,
      gone_sooner: 0,
      not_paid: 0,
    }
  },
  watch: {
    currentYear() {
      this.getFreeDays()
    },
  },
  mounted() {
    axios({
      method: 'get',
      url: 'employee/list/only_name_and_id',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(res => {
      this.employees = res.data
    })
  },
  methods: {
    handleYearChange(data) {
      this.currentYear = data.currentYear
    },
    getFreeDays() {
      axios({
        method: 'get',
        url: `freeday/list/year/${this.selected_employee}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.calendar_data = []

        this.freeday = 0
        this.holiday = 0
        this.sick_pay = 0
        this.unverified = 0
        this.late = 0
        this.gone_sooner = 0
        this.not_paid = 0

        res.data.forEach(element => {
          this.calendar_data.push({
            endDate: new Date(element.endDate),
            startDate: new Date(element.startDate),
            color: element.color,
          })

          // eslint-disable-next-line no-plusplus
          this.calendarKey++

          console.log(this.currentYear)

          if (element.startDate.slice(0, 4) === this.currentYear.toString()) {
            // eslint-disable-next-line radix
            if (element.color === 'green') this.freeday += 1
            // eslint-disable-next-line radix
            if (element.color === 'purple') {
              console.log(element)
              this.sick_pay += 1
            }
            // eslint-disable-next-line radix
            if (element.color === 'blue') this.holiday += 1
            // eslint-disable-next-line radix
            if (element.color === 'red') this.unverified += 1
            // eslint-disable-next-line radix
            if (element.color === 'yellow') this.late += 1
            // eslint-disable-next-line radix
            if (element.color === '#F05501') this.gone_sooner += 1
            // eslint-disable-next-line radix
            if (element.color === '#913831') this.not_paid += 1
          }
        })
      })
    },
  },
}

</script>

<style scoped>
    .calendar-settings{
        overflow-x: hidden;
        overflow-y: hidden;
        min-height: 500px;
    }
</style>
